import { Injectable } from '@angular/core';
import { WordpressPage } from '../models/wordpress-page';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WordpressApiService {

	private apiUrl = 'http://localhost.wp-ng.com/wp-json/wp/v2/';

	constructor(
		private httpClient: HttpClient
	)
	{
	}

	getPages(): Observable<WordpressPage[]>
	{
		const url = `${this.apiUrl}/pages/`;

		return this.httpClient
			.get(url)
			.pipe(
				map((data: any[]) =>
					data.map((datum: any) =>
						new WordpressPage(datum.id, datum.slug, datum.status, datum.title.rendered, datum.content.rendered)
					)
				)
			)
	}

	getPageById(id: number): Observable<WordpressPage>
	{
		const url = `${this.apiUrl}/pages/${id}`;

		return this.httpClient
			.get(url)
			.pipe(
				map((data: any) =>
					new WordpressPage(data.id, data.slug, data.status, data.title.rendered, data.content.rendered)
				)
			)
	}

	getPageBySlug(slug: string): Observable<WordpressPage>
	{
		const url = `${this.apiUrl}/pages?slug=${slug}`;

		return this.httpClient
			.get(url)
			.pipe(
                // tap(_ => console.log(_)),
                map((data: any) =>
                    new WordpressPage(data[0].id, data[0].slug, data[0].status, data[0].title.rendered, data[0].content.rendered)
                )
			)
	}
}
