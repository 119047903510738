import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '../app/pages/home/home.component';
import { AnOtherComponent } from '../app/pages/an-other/an-other.component';

const routes: Routes = [
    { path: '', component: HomeComponent, data: { view: 'home'} },
    { path: 'home', component: HomeComponent, data: { view: 'home'} },
    { path: 'an-other', component: AnOtherComponent, data: { view: 'another' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
