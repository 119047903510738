import { Component, OnInit } from '@angular/core';
import { WordpressPage } from '../../models/wordpress-page';
import { WordpressApiService } from '../../services/wordpress-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	page: WordpressPage;

  constructor(
			private wordpressApiService: WordpressApiService,
			private router: Router
  )
  {
      this.page = null;
  }

  ngOnInit(): void
  {
		const slug = this.router.url.split('/').pop();

		this.wordpressApiService.getPageBySlug(slug).subscribe((page: WordpressPage) =>
		{
			this.page = page;
		});
  }
}
