<p>These links won't refresh the page:</p>
<ul>
	<li *ngFor="let page of pages">
		<a routerLink="/{{ page.slug }}">
			{{ page.title }}
		</a>
	</li>
</ul>

<p>These will:</p>
<ul>
	<li *ngFor="let page of pages">
		<a href="/{{ page.slug }}">
			{{ page.title }}
		</a>
	</li>
</ul>

<img src="../assets/countdown.gif">

<router-outlet></router-outlet>
