import { Component, OnInit } from '@angular/core';
import { WordpressPage } from './models/wordpress-page';
import { WordpressApiService } from './services/wordpress-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	pages: WordpressPage[];

	constructor(
		private wordpressApiService: WordpressApiService
	)
	{
		this.pages = [];
	}

	ngOnInit(): void
  {
		this.wordpressApiService.getPages().subscribe((pages: WordpressPage[]) =>
		{
			this.pages = pages;
		});
  }
}
